"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.commaNumber = exports.censor = exports.camelCase = void 0;
function camelCase(input) {
    var output = input.toLowerCase();
    return output.split(' ').reduce(function (s, c) {
        return s + (c.charAt(0).toUpperCase() + c.slice(1));
    });
}
exports.camelCase = camelCase;
function censor(input, visibleLength) {
    return input.replace(/./g, function (_, i) {
        return i < visibleLength ? input[i] : '*';
    });
}
exports.censor = censor;
function commaNumber(input) {
    return input.toLocaleString('en-US');
}
exports.commaNumber = commaNumber;
